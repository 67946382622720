import React from 'react'

import FormComponent from './Form'
import { defaultSettings } from './defaults/settings'

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings }

    const { Component } = mergedSettings

    return {
        Component: (props) => <Component {...props} {...mergedSettings} />,
        FormComponent: (props) => <FormComponent {...props} {...mergedSettings} />,

        name: 'peracto/content/menuItems',
        version: '1.0.0',
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,
        createInitialState: () => ({
            menu: {},
            menuItem: {},
            menuItemPath: null,
            categoryPath: null,
        }),
    }
}

export default createPlugin
