import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import TestimonialsRenderer from "./Renderer";

const TestimonialsForm = lazyLoad(() => import("./Form"));

const TestimonialsPlugin = (settings) =>
    createPlugin({
        Renderer: TestimonialsRenderer,
        Controls: TestimonialsForm,
        ...settings,
    });

const Testimonials = TestimonialsPlugin();
export default Testimonials;

export { TestimonialsPlugin, TestimonialsRenderer };
