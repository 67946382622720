import React from "react";

const PanelsContent = ({
    flipped,
    largePanelImage,
    largePanelImageAlt,
    largePanelImageLink,
    largePanelImageLinkExternal,
    smallPanelImageTop,
    smallPanelImageTopAlt,
    smallPanelImageTopLink,
    smallPanelImageTopLinkExternal,
    smallPanelImageBottom,
    smallPanelImageBottomAlt,
    smallPanelImageBottomLink,
    smallPanelImageBottomLinkExternal,
}) => {
    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    return (
        <div>
            <div
                className={`grid grid-cols-1 gap-[15px] lg:grid-cols-6 lg:gap-[30px] ${
                    flipped ? "lg:flex-row-reverse" : ""
                }`}
            >
                <a
                    href={largePanelImageLink}
                    className="lg:col-span-4 w-full h-full"
                    {...(!inStorefront && {
                        onClick: (e) => {
                            e.preventDefault();
                        },
                    })}
                    {...(largePanelImageLinkExternal && {
                        target: "_blank",
                        rel: "noopener noreferrer",
                    })}
                >
                    <img
                        src={largePanelImage}
                        alt={largePanelImageAlt}
                        className="w-full"
                    />
                </a>
                <div
                    className={`lg:col-span-2 flex flex-col gap-y-[15px] lg:gap-y-[30px] ${
                        flipped ? "order-first" : ""
                    }`}
                >
                    <a
                        href={smallPanelImageTopLink}
                        className="w-full flex-1"
                        {...(!inStorefront && {
                            onClick: (e) => {
                                e.preventDefault();
                            },
                        })}
                        {...(smallPanelImageTopLinkExternal && {
                            target: "_blank",
                            rel: "noopener noreferrer",
                        })}
                    >
                        <img
                            src={smallPanelImageTop}
                            alt={smallPanelImageTopAlt}
                            className="w-full h-full"
                        />
                    </a>
                    <a
                        href={smallPanelImageBottomLink}
                        className="w-full flex-1"
                        {...(!inStorefront && {
                            onClick: (e) => {
                                e.preventDefault();
                            },
                        })}
                        {...(smallPanelImageBottomLinkExternal && {
                            target: "_blank",
                            rel: "noopener noreferrer",
                        })}
                    >
                        <img
                            src={smallPanelImageBottom}
                            alt={smallPanelImageBottomAlt}
                            className="w-full"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

const PanelsRenderer = ({ state, ...props }) => {
    return <PanelsContent {...state} {...props} />;
};

export default PanelsRenderer;
