import React from "react";

import FormComponent from "./Form";
import { defaultSettings } from "./defaults/settings";

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings };

    const { Component } = mergedSettings;

    return {
        Component: (props) => <Component {...props} {...mergedSettings} />,
        FormComponent: (props) => (
            <FormComponent {...props} {...mergedSettings} />
        ),

        name: "keylite/content/hero-banner",
        version: "1.0.0",
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,
        createInitialState: () => ({
            title: "Hero Banner",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin mollis turpis diam, id molestie felis scelerisque sed.",
            buttonText: "Call to action",
            buttonLink: "https://www.google.co.uk",
            isExternal: false,
            image: "https://placehold.co/800x800/png",
            imageAlt: "Image Alt",
        }),
    };
};

export default createPlugin;
