import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShield } from "@fortawesome/pro-regular-svg-icons/faShield";

export const defaultTranslations = {
    pluginName: "Hero Banner",
    pluginDescription: "A hero banner",
};

export const defaultSettings = {
    Component: () => <>Component for this plugin was not provided</>,
    FormComponent: () => <>There is no form for this block</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faShield} size="3x" />,
    name: "keylite/content/text-on-image",
};
