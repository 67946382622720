import React from 'react'
import EmblaCarousel from '../../../components/EmblaCarousel'

const OPTIONS = { loop: true }

const Component = ({
    state: {
        slides = [],
    },
}) => {
    return (
        <EmblaCarousel slides={slides} options={OPTIONS} />
    )
}

export default Component
