import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import HappyCustomersRenderer from "./Renderer";

const HappyCustomersForm = lazyLoad(() => import("./Form"));

const HappyCustomersPlugin = (settings) =>
    createPlugin({
        Renderer: HappyCustomersRenderer,
        Controls: HappyCustomersForm,
        ...settings,
    });

const HappyCustomers = HappyCustomersPlugin();
export default HappyCustomers;

export { HappyCustomersPlugin, HappyCustomersRenderer };
