import React, { useEffect } from "react";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import PlainTextarea from "@peracto/peracto-editor-ui/dist/PlainTextarea";
import File from "@peracto/peracto-editor-ui/dist/File";

import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

const CardsForm = ({ onChange, state: { cards, selectedCardIndex } }) => {
    const DEFAULT_CARD = {
        image: "",
        heading: "Card Heading",
        content: "Card content....",
        buttonText: "Click Here",
        url: "#",
        openInNewTab: false,
        border: false,
    };

    useEffect(() => {
        return () => {
            onChange({ selectedCardIndex: null });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {cards?.length === 0 && (
                <>
                    <p>Click 'Add Card' to get started</p>
                    <hr />
                </>
            )}
            {selectedCardIndex !== null &&
                cards &&
                cards[selectedCardIndex] && (
                    <>
                        <File
                            label="Image"
                            value={cards[selectedCardIndex].image || ""}
                            onChange={(value) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].image = value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            isInline={false}
                        />

                        <TextInput
                            label="Heading"
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].heading =
                                    e.target.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].heading}
                        />

                        <PlainTextarea
                            label="Content"
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].content =
                                    e.target.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].content}
                        />

                        <TextInput
                            label="Button Text"
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].buttonText =
                                    e.target.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].buttonText}
                        />

                        <TextInput
                            label="Button Link"
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].url =
                                    e.target.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].url}
                        />

                        <Checkbox
                            label="Open in new tab"
                            checked={cards[selectedCardIndex].openInNewTab}
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].openInNewTab =
                                    e.target.checked;
                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                        />

                        <Checkbox
                            label="Show Border"
                            checked={cards[selectedCardIndex].border}
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].border =
                                    e.target.checked;
                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                        />

                        <button
                            className="btn btn-sm btn-danger w-100 mt-2"
                            onClick={(e) => {
                                const cardsClone = [...cards];

                                cardsClone.splice(selectedCardIndex, 1);

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />{" "}
                            Remove Card
                        </button>
                    </>
                )}

            <hr className="my-2" />

            <button
                className="btn btn-sm btn-secondary w-100"
                disabled={cards.length >= 3}
                onClick={(e) => {
                    e.preventDefault();
                    if (cards.length < 3) {
                        const cardsClone = [...cards, DEFAULT_CARD];
                        onChange({
                            cards: cardsClone,
                        });
                    }
                }}
            >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add
                Card
            </button>
        </>
    );
};

export default CardsForm;
