const sharedClasses = "h-[45px] px-[30px] relative inline-flex items-center justify-center whitespace-nowrap rounded-full text-base font-bold leading-[18px] border border-transparent transition-colors active:ring-4 disabled:opacity-25 disabled:cursor-not-allowed font-heading";

export const buttonVariants = {
    primary: {
        solidClasses: `${sharedClasses} bg-brand-primary text-brand-white border-brand-primary hover:bg-brand-primary-darken active:bg-brand-primary-darken active:ring-brand-primary-darken/40 hover:text-brand-white hover:no-underline`,
        outlinedClasses: `${sharedClasses} bg-brand-primary text-brand-white border-brand-primary hover:bg-brand-primary-darken active:bg-brand-primary-darken active:ring-brand-primary-darken/40 hover:text-brand-white hover:no-underline`,
    },
    secondary: {
        solidClasses: `${sharedClasses} bg-brand-secondary text-brand-white border-brand-secondary hover:bg-brand-secondary-darken active:bg-brand-secondary-darken active:ring-brand-secondary-darken/40 hover:text-brand-white hover:no-underline`,
        outlinedClasses: `${sharedClasses} bg-brand-secondary text-brand-white border-brand-secondary hover:bg-brand-secondary-darken active:bg-brand-secondary-darken active:ring-brand-secondary-darken/40 hover:text-brand-white hover:no-underline`,
    },
    tertiary: {
        solidClasses: `${sharedClasses} bg-transparent text-brand-secondary !border-brand-secondary hover:text-brand-white hover:!bg-brand-secondary active:text-brand-white active:bg-brand-secondary active:ring-brand-secondary/40 hover:text-brand-white hover:no-underline`,
        outlinedClasses: `${sharedClasses} bg-transparent text-brand-secondary !border-brand-secondary hover:text-brand-white hover:!bg-brand-secondary active:text-brand-white active:bg-brand-secondary active:ring-brand-secondary/40 hover:text-brand-white hover:no-underline`,
    },
    black: {
        solidClasses: `${sharedClasses} bg-brand-black text-brand-white border-brand-black hover:bg-brand-black-darken active:bg-brand-black-darken active:ring-brand-black-darken/40 hover:text-brand-white hover:no-underline`,
        outlinedClasses: `${sharedClasses} bg-brand-black text-brand-white border-brand-black hover:bg-brand-black-darken active:bg-brand-black-darken active:ring-brand-black-darken/40 hover:text-brand-white hover:no-underline`,
    },
    white: {
        solidClasses: `${sharedClasses} bg-brand-white text-brand-black border-brand-white hover:text-brand-black hover:bg-brand-white-darken active:bg-brand-white-darken active:ring-brand-white-darken/40 hover:text-brand-black hover:no-underline`,
        outlinedClasses: `${sharedClasses} bg-brand-white text-brand-black border-brand-white hover:text-brand-black hover:bg-brand-white-darken active:bg-brand-white-darken active:ring-brand-white-darken/40 hover:text-brand-black hover:no-underline`,
    },
}
