import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import IconAndTextRenderer from "./Renderer/IconAndTextRenderer";

const IconAndTextForm = lazyLoad(() => import("./Form/IconAndTextForm"));

const IconAndTextPlugin = (settings) =>
    createPlugin({
        Renderer: IconAndTextRenderer,
        Controls: IconAndTextForm,
        ...settings,
    });

const IconAndText = IconAndTextPlugin();
export default IconAndText;

export { IconAndTextPlugin, IconAndTextRenderer };
