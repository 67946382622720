import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { useConfig } from "@peracto/peracto-config";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import Select from "@peracto/peracto-editor-ui/dist/Select";
import Slider from "@peracto/peracto-editor-ui/dist/Slider";
import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";

import styled from "styled-components";
import debounce from "debounce-promise";
import axios from "axios";
import startCase from "lodash/startCase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faSave } from "@fortawesome/pro-regular-svg-icons/faSave";

const DEFAULT_ATTRIBUTE = {
    attribute: "",
    value: "",
    label: "Please select...",
};

const AttributeDivider = styled.div`
    height: 30px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        display: inline-block;
        content: ${(props) =>
            props.attributeOperator ? `'${props.attributeOperator}'` : ""};
        background-color: ${(props) => props.theme.admin.colors.brandDark};
        padding-left: 10px;
        padding-right: 10px;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background-color: ${(props) => props.theme.admin.colors.greyLight};
    }
`;

const ProductsForm = ({ onChange, state }) => {
    const {
        numberOfProducts = 4,
        attributes = [{ ...DEFAULT_ATTRIBUTE }],
        attributeOperator = "and",
        border = true,
    } = state;

    const [localAttributes, setLocalAttributes] = useState(attributes);

    const config = useConfig();
    const API_URL = config.get("api");
    const { theme } = config.get("editorConfig") || [];

    const themeColours = [];

    if (theme?.colors?.brand) {
        for (const [label, colour] of Object.entries(theme.colors.brand)) {
            themeColours.push({
                label: startCase(label),
                value: colour,
            });
        }
    }

    const themeFonts = [];

    if (theme?.fonts) {
        Object.keys(theme.fonts).map((font) =>
            themeFonts.push({
                label: startCase(font),
                value: font,
            })
        );
    }

    const fetchAttributes = async (input) => {
        const { data } = await axios.get(
            `${API_URL}/attributes?label=${input || ""}&filterable=true`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        const parsedData = data["hydra:member"];

        const values = parsedData.map((form) => ({
            label: form.label,
            value: form.code,
        }));

        return [{ label: "SKU", value: "sku" }, ...values];
    };

    const debouncedFetchAttributes = debounce(fetchAttributes, 200);

    return (
        <>
            <div className="form-group mb-0">
                <h6>Attributes</h6>
                {localAttributes.map((attr, idx) => (
                    <React.Fragment key={`attributes_${attr.label}_${idx}`}>
                        {idx > 0 && (
                            <AttributeDivider
                                attributeOperator={attributeOperator}
                            />
                        )}

                        <label className="mb-1">Attribute Name</label>

                        <AsyncSelect
                            className="w-100 mb-2"
                            loadOptions={(input) =>
                                debouncedFetchAttributes(input)
                            }
                            isSearchable={true}
                            defaultOptions={true}
                            value={{
                                label: attr.label,
                                value: attr.attribute,
                            }}
                            onChange={(option) => {
                                let attrs = [...localAttributes];
                                attrs[idx].attribute = option.value;
                                attrs[idx].label = option.label;

                                setLocalAttributes([...attrs]);
                            }}
                            placeholder="Select an attribute..."
                            classNamePrefix="peracto-select"
                            noOptionsMessage={({ inputValue }) => {
                                if (inputValue.length > 0) {
                                    return `No attributes found for '${inputValue}'.`;
                                } else {
                                    return "Enter text to begin searching.";
                                }
                            }}
                        />

                        <TextInput
                            label="Attribute Value"
                            onChange={(e) => {
                                const attrs = [...localAttributes];
                                attrs[idx].value = e.target.value;

                                setLocalAttributes([...attrs]);
                            }}
                            value={localAttributes[idx].value}
                        />

                        {idx > 0 && (
                            <button
                                className="btn btn-sm btn-danger w-100 my-2"
                                onClick={(e) => {
                                    const attrs = [...localAttributes];
                                    attrs.splice(idx, 1);

                                    setLocalAttributes([...attrs]);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="mr-2"
                                />{" "}
                                Remove Attribute
                            </button>
                        )}
                    </React.Fragment>
                ))}
            </div>

            <AttributeDivider />

            <button
                className="btn btn-sm btn-secondary w-100 my-2"
                onClick={(e) => {
                    const attrs = [
                        ...localAttributes,
                        { ...DEFAULT_ATTRIBUTE },
                    ];

                    setLocalAttributes(attrs);
                }}
            >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add
                Attribute
            </button>

            <hr />

            <button
                className="btn btn-sm btn-success w-100 my-2"
                onClick={(e) => {
                    onChange({
                        attributes: localAttributes,
                    });
                }}
            >
                <FontAwesomeIcon icon={faSave} className="mr-2" /> Save
                Attributes
            </button>

            <Select
                label="Attribute Operator"
                options={[
                    { label: "And", value: "and" },
                    { label: "Or", value: "or" },
                ]}
                value={attributeOperator}
                onChange={(e) => onChange({ attributeOperator: e.value })}
            />

            <Slider
                label={`Number of Products: ${numberOfProducts}`}
                min={1}
                max={12}
                step={1}
                value={numberOfProducts}
                onChange={(e) => onChange({ numberOfProducts: e.target.value })}
            />

            <Checkbox
                label="Show Border"
                checked={border}
                onChange={(e) => onChange({ border: e.target.checked })}
            />
        </>
    );
};

export default ProductsForm;
