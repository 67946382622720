import React from 'react'

import FormComponent from './Form'
import { defaultSettings } from './defaults/settings'

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings }

    const { Component } = mergedSettings

    return {
        Component: (props) => <Component {...props} {...mergedSettings} />,
        FormComponent: (props) => <FormComponent {...props} {...mergedSettings} />,

        name: 'keylite/content/carousel',
        version: '1.0.0',
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,
        createInitialState: () => ({
            numberOfSlides: 3,
            slides: [
                {
                    image: 'https://placehold.co/800x800/png',
                    imageAlt: 'Image Alt',
                },
                {
                    image: 'https://placehold.co/800x800/png',
                    imageAlt: 'Image Alt',
                },
                {
                    image: 'https://placehold.co/800x800/png',
                    imageAlt: 'Image Alt',    
                }
            ],
        }),
    }
}

export default createPlugin
