import React from 'react'
import TextInput from '@peracto/peracto-editor-ui/dist/TextInput'
import File from '@peracto/peracto-editor-ui/dist/File'

const Form = ({
    onChange,
    state: {
        title,
        text,
        buttonText,
        buttonLink,
        image,            
        imageAlt,
    },
}) => {
    return (
        <>
            <TextInput
                label="Title"
                value={title}
                onChange={e => onChange({ title: e.target.value })}
            />
            <TextInput
                label="Text"
                value={text}
                onChange={e => onChange({ text: e.target.value })}
            />
            <TextInput
                label="Button Text"
                value={buttonText}
                onChange={e => onChange({ buttonText: e.target.value })}
            />
            <TextInput label="Button Link" 
                value={buttonLink} 
                onChange={e => onChange({ buttonLink: e.target.value })} 
            />
            <File
                label="Image"
                value={image}
                onChange={value => onChange({ image: value })}
                isInline={false}
            />
            <TextInput
                label="Image Alt"
                value={imageAlt}
                onChange={e => onChange({ imageAlt: e.target.value })}
            />
        </>
    )
}

export default Form
