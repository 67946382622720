/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { useConfig } from '@peracto/peracto-config'

const ComponentWrapper = styled.div`
    position: relative;
    /* this padding property is ignored if theres aspect ratio set */
    padding: ${props => (props.aspectRatio ? null : props.isPreviewMode ? '0px' : '0.625em')};

    max-width: 100%;

    padding-bottom: ${props =>
        props.aspectRatio
            ? () => {
                  switch (props.aspectRatio) {
                      case '4:3':
                          return `75%`
                      default:
                          return `100%`
                  }
              }
            : props.isPreviewMode
            ? '0px'
            : '0.625em'};

    @media all and (min-width: ${props => props?.screens?.sm || '576px'}) {
        padding-bottom: ${props =>
            props.aspectRatio
                ? () => {
                      switch (props.aspectRatio) {
                          case '4:3':
                              return `75%`
                          case '16:9':
                              return `56.25%`
                          case '2:1':
                              return `50%`
                          default:
                              return `100%`
                      }
                  }
                : props.isPreviewMode
                ? '0px'
                : '0.625em'};
    }

    @media all and (min-width: ${props => props?.screens?.md || '768px'}) {
        width: 100%;
    }

    @media all and (min-width: ${props => props?.screens?.lg || '992px'}) {
        width: 100%;
    }

    @media all and (min-width: ${props => props?.screens?.xl || '1210px'}) {
        max-width: ${props => {
            switch (props.container) {
                case 'standard':
                    return `${props?.screens?.xl || '1210px'}`
            }
        }};
    }

    margin-left: ${props => (props.container !== 'full' ? 'auto' : '0')};
    margin-right: ${props => (props.container !== 'full' ? 'auto' : '0')};

    > div {
        ${props =>
            props.aspectRatio
                ? `position: absolute;
                    top: 0;
                    left; 0;
                    width: 100%;
                    height: 100%;`
                : null};

        display: ${props => (props.aspectRatio ? 'flex' : 'static')};
        justify-content: center;
        align-items: ${props => props.alignContent};

        &.mobile-two-col-layout {
            // Ory's grid's 'sm' breakpoint starts at 768px.
            // We have to use max-width here because otherwise it'll override the grid layout on
            // 'sm' and up due to the increased specificity.
            @media all and (max-width: 767px) {
                .ory-cell-xs-12 {
                    width: 50%;
                    min-width: 50%;
                    flex-basis: 50%;

                    .ory-cell-xs-12 {
                        width: 100%;
                        min-width: 100%;
                        flex-basis: 100%;
                    }
                }
            }
        }

        &.container__match-height {
            > .ory-row,
            > .ory-dimensions > .ory-row-droppable-container > .ory-row {
                align-items: stretch !important;

                &.ory-cell-inner > .ory-cell * {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    max-width: 100%;
                }

                .content__text {
                    margin: 0 !important;
                }
            }

            .ory-cell-inner {
                width: 100%;
            }
        }

        &.container__align-content--top {
            > .ory-row,
            > .ory-dimensions > .ory-row-droppable-container > .ory-row {
                align-items: flex-start;
            }
        }

        &.container__align-content--center {
            > .ory-row,
            > .ory-dimensions > .ory-row-droppable-container > .ory-row {
                align-items: center;
            }
        }

        &.container__align-content--end {
            > .ory-row,
            > .ory-dimensions > .ory-row-droppable-container > .ory-row {
                align-items: flex-end;
            }
        }
    }
`

const Component = ({
    state: {
        containerSize = 'full',
        containerType = 'fluid',
        aspectRatio = '',
        alignContent = 'flex-start',
        matchContentHeight,
        mobileTwoColumnLayout,
    },
    children,
    isPreviewMode,
}) => {
    const inStorefront = !window?.location?.pathname.includes('/content/edit/')

    const config = useConfig()

    const editorConfig = config.get('editorConfig')

    return (
        <ComponentWrapper
            container={containerSize}
            containerType={containerType}
            isPreviewMode={inStorefront}
            aspectRatio={aspectRatio}
            alignContent={alignContent}
            screens={editorConfig?.screens}
        >
            <div
                className={classNames({
                    'container__match-height': matchContentHeight,
                    'container__align-content--top': alignContent === 'flex-start',
                    'container__align-content--center': alignContent === 'center',
                    'container__align-content--end': alignContent === 'flex-end',
                    'mobile-two-col-layout': mobileTwoColumnLayout,
                })}
            >
                {children}
            </div>
        </ComponentWrapper>
    )
}

export default Component
