import createPlugin from './createPlugin'

import { default as MenuItemsComponent } from './Component'

const menuItemsPlugin = settings =>
    createPlugin({
        Component: MenuItemsComponent,
        ...settings,
    })

const menuItems = menuItemsPlugin()
export default menuItems

export { menuItemsPlugin, MenuItemsComponent }
