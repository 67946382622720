import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import InfoPanelsRenderer from "./Renderer";

const InfoPanelsForm = lazyLoad(() => import("./Form"));

const InfoPanelsPlugin = (settings) =>
    createPlugin({
        Renderer: InfoPanelsRenderer,
        Controls: InfoPanelsForm,
        ...settings,
    });

const InfoPanels = InfoPanelsPlugin();
export default InfoPanels;

export { InfoPanelsPlugin, InfoPanelsRenderer };
