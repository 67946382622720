import React from "react";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";

import AccordionContent from "./AccordionContent";

let Icon = () => (
    <div className="flex items-center justify-center w-full py-10">
        <FontAwesomeIcon icon={faChevronDown} size="6x" />
    </div>
);

const AccordionComponent = ({
    state: { accordion = [], selectedAccordionIndex = 0 },
    isEditMode,
    focused,
    onChange,
}) => {
    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    return (
        <>
            {accordion?.length > 0 ? (
                <div
                    data-testid="accordion"
                    className="border-b border-b-brand-grey"
                >
                    {accordion?.map((item, index) => (
                        <Disclosure
                            key={`accordion-${index}`}
                            defaultOpen={item.openByDefault}
                        >
                            {({ open }) => (
                                <div
                                    className={`content__accordion`}
                                    data-testid={`accordion__item-${index}`}
                                    style={{
                                        boxShadow:
                                            selectedAccordionIndex === index &&
                                            focused &&
                                            "inset 0 0 0 3px yellow",
                                    }}
                                >
                                    <Disclosure.Button
                                        className={`w-full  focus:outline-none pt-[15px] px-[15px] ${
                                            open ? "pb-0" : "pb-[15px]"
                                        } border-t border-brand-grey`}
                                        {...(isEditMode && {
                                            onClick: () => {
                                                onChange({
                                                    selectedAccordionIndex:
                                                        index,
                                                });
                                            },
                                        })}
                                        data-testid={`accordion__item-${index}--header`}
                                    >
                                        <div
                                            className={` flex items-center justify-between font-bold text-lg`}
                                        >
                                            {item.label}
                                            <FontAwesomeIcon
                                                className="text-brand-secondary"
                                                icon={open ? faMinus : faPlus}
                                            />
                                        </div>
                                    </Disclosure.Button>

                                    <Disclosure.Panel>
                                        <div className={`mt-[15px] px-[15px]`}>
                                            <AccordionContent
                                                content={item.text}
                                            />
                                        </div>
                                    </Disclosure.Panel>
                                </div>
                            )}
                        </Disclosure>
                    ))}
                </div>
            ) : inStorefront ? (
                <></>
            ) : (
                <Icon />
            )}
        </>
    );
};

export default AccordionComponent;
