import React, { useEffect } from "react";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";

import File from "@peracto/peracto-editor-ui/dist/File";

import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

const HappyCustomersForm = ({
    onChange,
    state: { images, border, selectedCardIndex },
}) => {
    const DEFAULT_CARD = {
        image: "https://placehold.co/170x114/EEE/31343C",
        link: "",
        alt: "",
        openInNewTab: false,
    };

    useEffect(() => {
        return () => {
            onChange({ selectedCardIndex: null });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {images?.length === 0 && (
                <>
                    <p>Click 'Add Customer' to get started</p>
                    <hr />
                </>
            )}
            {selectedCardIndex !== null &&
                images &&
                images[selectedCardIndex] && (
                    <>
                        <File
                            label="Customer Image"
                            value={images[selectedCardIndex].image || ""}
                            onChange={(value) => {
                                const imagesClone = [...images];
                                imagesClone[selectedCardIndex].image = value;

                                onChange({
                                    images: imagesClone,
                                });
                            }}
                            isInline={false}
                        />

                        <TextInput
                            label="Customer Name"
                            onChange={(e) => {
                                const imagesClone = [...images];
                                imagesClone[selectedCardIndex].alt =
                                    e.target.value;

                                onChange({
                                    images: imagesClone,
                                });
                            }}
                            value={images[selectedCardIndex].alt}
                        />

                        <TextInput
                            label="Image Link"
                            onChange={(e) => {
                                const imagesClone = [...images];
                                imagesClone[selectedCardIndex].link =
                                    e.target.value;

                                onChange({
                                    images: imagesClone,
                                });
                            }}
                            value={images[selectedCardIndex].link}
                        />

                        <Checkbox
                            label="Open in new tab"
                            checked={images[selectedCardIndex].openInNewTab}
                            onChange={(e) => {
                                const imagesClone = [...images];
                                imagesClone[selectedCardIndex].openInNewTab =
                                    e.target.checked;
                                onChange({
                                    images: imagesClone,
                                });
                            }}
                        />

                        <button
                            className="btn btn-sm btn-danger w-100 mt-2"
                            onClick={(e) => {
                                const imagesClone = [...images];

                                imagesClone.splice(selectedCardIndex, 1);

                                onChange({
                                    images: imagesClone,
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />{" "}
                            Remove Customer
                        </button>
                    </>
                )}

            <hr className="my-2" />

            <button
                className="btn btn-sm btn-secondary w-100"
                onClick={(e) => {
                    e.preventDefault();

                    const imagesClone = [...images, DEFAULT_CARD];
                    onChange({
                        images: imagesClone,
                    });
                }}
            >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add
                Customer
            </button>
        </>
    );
};

export default HappyCustomersForm;
