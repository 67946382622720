import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import File from '@peracto/peracto-editor-ui/dist/File'
import Select from '@peracto/peracto-editor-ui/dist/Select'
import { useSettings } from '@peracto/peracto-hooks'

import debounce from 'debounce-promise'
import axios from 'axios'

import { useConfig } from '@peracto/peracto-config'

const MenuItemsForm = ({
    onChange,
    state: {
        menu = {},
        menuItem = {},
        layout = 'grid',
        fallbackImage,
    },
}) => {
    const [parentMenu, setParentMenu] = useState(menu)

    const { values: p_values } = useSettings()
    const configCategoryPath = p_values?.sub_urls?.sitemap_categories_sub_url || 'category'

    const config = useConfig()
    const API_URL = config.get('api')

    const LAYOUT_MODES = [
        { label: 'Grid', value: 'grid' },
        { label: 'List', value: 'list' },
    ]

    useEffect(() => {
        onChange({ categoryPath: configCategoryPath })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configCategoryPath])

    const fetchMenus = async input => {
        const { data } = await axios.get(`${API_URL}/menus?name=${input || ''}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })

        const parsedData = data['hydra:member']

        const values = parsedData.map(menu => ({
            label: menu.name,
            value: `/menus/${menu.code}`,
        }))

        return values
    }

    const debouncedFetchMenus = debounce(fetchMenus, 200)

    let menuItems = []

    const formatMenuItems = menu => {
        menu.forEach(item => {
            menuItems.push({
                label: item.linkText,
                value: item.path,
            })

            if (item?.children?.length > 0) {
                formatMenuItems(item.children)
            }
        })
        return menuItems
    }

    const fetchMenuItems = async input => {
        menuItems = []

        const { data } = await axios.get(`${API_URL}${parentMenu.value}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })

        const values = formatMenuItems(data.menuItems)

        return values.filter(val => val.label.toLowerCase().includes(input.toLowerCase()))
    }

    const debouncedFetchMenuItems = debounce(fetchMenuItems, 200)

    return (
        <>
            <div className="form-group">
                <label>Menu</label>
                <AsyncSelect
                    className="w-100"
                    loadOptions={input => debouncedFetchMenus(input)}
                    isSearchable={true}
                    defaultOptions={true}
                    value={menu}
                    onChange={option => {
                        setParentMenu(option)
                        onChange({ menu: option })
                        onChange({ menuItem: {} })
                    }}
                    placeholder="Select a menu..."
                    classNamePrefix="peracto-select"
                    noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length > 0) {
                            return `No menus found for '${inputValue}'.`
                        } else {
                            return 'Enter text to begin searching.'
                        }
                    }}
                />
            </div>

            <div className="form-group">
                <label>Menu Item</label>
                <AsyncSelect
                    className="w-100"
                    loadOptions={input => debouncedFetchMenuItems(input)}
                    isSearchable={true}
                    value={menuItem}
                    onChange={option => {
                        onChange({ menuItem: option })
                        onChange({ menuItemPath: option.value })
                    }}
                    isDisabled={!parentMenu}
                    placeholder="Select a menu item..."
                    classNamePrefix="peracto-select"
                    noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length > 0) {
                            return `No menus found for '${inputValue}'.`
                        } else {
                            return 'Enter text to begin searching.'
                        }
                    }}
                />
            </div>

            <Select
                label="Layout Mode"
                options={LAYOUT_MODES}
                value={layout}
                onChange={e => onChange({ layout: e.value })}
            />

            <File
                label="Fallback Image"
                value={fallbackImage}
                onChange={value => onChange({ fallbackImage: value })}
            />
        </>
    )
}

export default MenuItemsForm
