import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleWide } from '@fortawesome/pro-regular-svg-icons/faRectangleWide'
import Text from '@peracto/peracto-content/dist/Editor/ContentBlocks/content/Text'

export const defaultTranslations = {
    pluginName: 'Container',
    pluginDescription: 'A container blocks can be placed into',
}

export const defaultSettings = {
    defaultPlugin: Text,
    Component: () => <>Component for this plugin was not provided</>,
    FormComponent: () => <>There is no form for this block</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faRectangleWide} size="3x" />,
    name: 'peracto/layout/container',
}
