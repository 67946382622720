import React from "react";

import { defaultSettings } from "./defaults/settings";
import Component from "./Component";
import Form from "./Form/ProductsForm";

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings };

    return {
        FormComponent: (props) => <Form {...props} />,

        Component: (props) => <Component {...props} {...mergedSettings} />,

        name: "keylite/content/products",
        version: "1.0.0",
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        createInitialState: () => ({
            numberOfProducts: 4,
            category: "",
            sku: "",
            fallbackImage: "",
            multiple: true,
            border: true,
        }),
    };
};

export default createPlugin;
