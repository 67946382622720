import React from "react";

import { defaultSettings } from "./defaults/settings";
import Component from "./Component";
import Form from "./Form/PanelsForm";

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings };

    return {
        Component: (props) => <Component {...props} {...mergedSettings} />,
        FormComponent: (props) => <Form {...props} />,

        name: "keylite/content/panels",
        version: "1.0.0",
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        createInitialState: () => ({
            flipped: false,
            largePanelImage: "https://placehold.co/960x684/EEE/31343C",
            largePanelImageAlt: "",
            largePanelImageLink: "",
            largePanelImageLinkExternal: false,
            smallPanelImageTop: "https://placehold.co/500x348/EEE/31343C",
            smallPanelImageTopAlt: "",
            smallPanelImageTopLink: "",
            smallPanelImageTopLinkExternal: false,
            smallPanelImageBottom: "https://placehold.co/500x348/EEE/31343C",
            smallPanelImageBottomAlt: "",
            smallPanelImageBottomLink: "",
            smallPanelImageBottomLinkExternal: false,
        }),
    };
};

export default createPlugin;
