import lazyLoad from "@loadable/component";

import createPlugin from "./createPlugin";
import AccordionRenderer from "./Renderer/AccordionRenderer";
const AccordionForm = lazyLoad(() => import("./Form/AccordionForm"));

const accordionPlugin = (settings) =>
    createPlugin({
        Renderer: AccordionRenderer,
        Controls: AccordionForm,
        ...settings,
    });

const accordion = accordionPlugin();
export default accordion;

export { accordionPlugin, AccordionRenderer };
