import React from 'react'
import Nestable from 'react-nestable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines } from '@fortawesome/pro-regular-svg-icons/faGripLines'
import styled from '@emotion/styled'

const SortableRow = styled.div`
    cursor: grab;
    background-color: #666;
    border-bottom: 1px solid;
    padding: 10px 15px;
    border-radius: 3px;

    &:last-child {
        border-bottom: none;
    }

    &:active {
        cursor: grabbing;
        background-color: #888;
    }
`

const ListItem = ({ item }) => (
    <SortableRow>
        <FontAwesomeIcon icon={faGripLines} className="mr-2" /> {item.label}
    </SortableRow>
)

const AccordionItemSort = ({ accordion, onChange }) => {
    const accordionItems = accordion.map((acc, idx) => ({ ...acc, id: idx }))
    return (
        <Nestable
            items={accordionItems}
            renderItem={ListItem}
            onChange={items => onChange({ accordion: items })}
            maxDepth={1}
        />
    )
}

export default AccordionItemSort
