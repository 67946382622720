import React from 'react'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

const EmblaCarousel = (props) => {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return (
    <div className="relative">
        <section className="mx-auto">
            <div className="overflow-hidden rounded-[20px] relative" ref={emblaRef}>
                <div className="flex touch-pan-y touch-pinch-zoom ml-[calc(30px*-1)]">
                    {slides?.map((slide, index) => (
                        <div className="flex-[0_0_100%] min-w-0 pl-[30px] min-h-[336px] lg:min-h-[600px] relative" key={index}>
                            <img src={slide?.image} alt={slide?.imageAlt || ''} className="w-full h-full object-cover absolute left-0 top-0" />
                        </div>
                    ))}
                </div>
                <div>
                    <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                    <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                </div>
            </div>
            
            <div>
                <div className="mt-[15px] flex items-center gap-x-[10px] justify-center">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                        key={index}
                        onClick={() => onDotButtonClick(index)}
                        className={'w-[10px] h-[10px] rounded-full border border-brand-grey'.concat(
                            index === selectedIndex ? ' bg-brand-secondary !border-brand-secondary' : ''
                        )}
                        />
                    ))}
                </div>
            </div>
        </section>
    </div>
  )
}

export default EmblaCarousel
