import React from "react";

const Component = ({
    state: { title, text, buttonText, buttonLink, isExternal, image, imageAlt },
}) => {
    return (
        <div
            data-testid="hero-banner"
            className="relative items-center lg:min-h-screen bg-cover flex"
            style={{
                backgroundImage: `url(${image})`,
            }}
        >
            <div
                data-testid="split-hero__desktop-overlay"
                className="lg:bg-black-vertical-gradient absolute inset-0 z-10 hidden lg:block"
            ></div>
            <div
                data-testid="split-hero__mobile-overlay"
                className="absolute inset-0 z-10 h-full bg-black/40 lg:hidden"
            ></div>

            <div className="relative mx-auto flex h-full items-center justify-center text-brand-white">
                <div className="container relative z-20 py-[60px] text-center lg:max-w-[480px]">
                    {title?.length > 0 && (
                        <h1
                            data-testid="split-hero__homeowners-title"
                            className="heading1-huge mb-[15px]"
                        >
                            {title}
                        </h1>
                    )}

                    {text?.length > 0 && (
                        <p
                            data-testid="split-hero__homeowners-text"
                            className="text-base lg:text-xl"
                        >
                            {text}
                        </p>
                    )}

                    {buttonText?.length > 0 && (
                        <a
                            data-testid="split-hero__homeowners-link !no-underline"
                            href={buttonLink || "#"}
                        >
                            <button className="relative inline-flex items-center justify-center whitespace-nowrap rounded-full text-base font-bold leading-[18px] border transition-colors active:ring-4 disabled:opacity-25 disabled:cursor-not-allowed font-heading bg-transparent text-brand-white border-brand-white hover:text-brand-black !hover:bg-white active:text-brand-black !active:bg-brand-white active:ring-brand-white/40 h-[45px] px-[30px] w-auto mt-[45px]">
                                {buttonText}
                            </button>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Component;
