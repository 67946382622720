import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentSmile } from "@fortawesome/pro-regular-svg-icons/faCommentSmile";

export const defaultTranslations = {
    pluginName: "Happy Customers",
    pluginDescription: "Add a row of logos with optional links",
};

export const defaultSettings = {
    Controls: () => <>Controls for this plugin were not provided</>,
    Renderer: () => <>Renderer for this plugin was not provided</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faCommentSmile} size="3x" />,
};
