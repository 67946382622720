/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import startCase from 'lodash/startCase'
import { Field } from 'formik'

import {
    Form,
    Select,
    Checkbox,
    Group,
    Input,
    FormActions,
    Modal,
    ImagePicker,
    Tags,
} from '@peracto/peracto-ui'

import { DELETE, useClient } from '@peracto/client'
import { useSettings } from '@peracto/peracto-hooks'
import { useConfig } from '@peracto/peracto-config'

import LocationManagement from '../../../../node_modules/@peracto/peracto-catalogue/dist/components/Products/ProductForm/LocationManagement'
import { convertObjectToOptions, DEFAULT_PRICE_TYPES, DEFAULT_PRODUCT_AVAILABILITY_VALUES, DEFAULT_PRODUCT_STATUS_VALUES } from '../../../../node_modules/@peracto/peracto-catalogue/dist/components/Products/util'

export const MODE_ADD = 'add';
export const MODE_EDIT = 'edit';

const ProductVariantsForm = ({
                                 mode = MODE_EDIT,
                                 groupAttributes = [],
                                 values,
                                 onDuplicate = () => {},
                                 productResources = {},
                                 ...props
                             }) => {
    const config = useConfig()
    const { id, variantId } = useParams()

    const { products } = config.get('features', {})
    const showPricesAsCurrency = products?.showPricesAsCurrency ?? true
    const readOnly = products?.readOnly
    const currencySymbols = products?.currencySymbols ?? {}

    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [redirect, setRedirect] = useState()

    const { client } = useClient()
    const { groups, values: p_values } = useSettings()

    const AVAILABILITY_OPTIONS =
        p_values?.availability?.length > 0
            ? convertObjectToOptions(p_values?.availability)
            : DEFAULT_PRODUCT_AVAILABILITY_VALUES

    const STATUS_OPTIONS =
        p_values?.productStatuses?.length > 0
            ? convertObjectToOptions(p_values?.productStatuses)
            : DEFAULT_PRODUCT_STATUS_VALUES

    const PRICE_OPTIONS = p_values?.price?.length > 0 ? p_values.price : DEFAULT_PRICE_TYPES

    const WEIGHT_UNIT = p_values?.weight_unit

    const resource_types = groups?.resources?.values?.resource_types?.input?.options

    const { image, ...resources } = resource_types

    const defaultPricesValues = {}
    PRICE_OPTIONS.forEach(option => (defaultPricesValues[option] = ''))

    const onDelete = async () => {
        try {
            await client(DELETE, 'product-variants', {
                id: `/product-variants/${variantId}`,
            })

            toast.success('Product variant deleted successfully!')
            setRedirect(`/products/${id}/variants`)
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    useEffect(() => {
        if (!products?.productVariants) {
            setRedirect(`/products/${id}`)
        }
    }, [products])

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            {mode === MODE_EDIT && !readOnly && (
                <FormActions>
                    <a onClick={() => setShowDuplicateDialog(true)} data-testid="duplicate-product">
                        <FontAwesomeIcon icon={faCopy} className="mr-2" />
                        Duplicate
                    </a>
                    <a
                        className="text-danger"
                        onClick={() => setShowDeleteDialog(true)}
                        data-testid="delete-product-variant"
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        Delete Product Variant
                    </a>
                </FormActions>
            )}

            <Form
                autoComplete="off"
                {...props}
                values={{
                    sku: '',
                    mpn: '',
                    gtin: '',
                    weight: '',
                    status: STATUS_OPTIONS[0].value,
                    availability: AVAILABILITY_OPTIONS[0].value,
                    variantResources: {
                        image: [],
                        document: [],
                        video: [],
                        youtube: [],
                    },
                    manageStock: false,
                    default: false,
                    ...defaultPricesValues,
                    ...values,
                }}
            >
                <div data-testid="core-section">
                    <Group key="core" id="core" name="Core">
                        <div className="form-row">
                            <div className="col-12 col-lg-4">
                                <Input
                                    name="sku"
                                    label="SKU"
                                    help="Stock Keeping Unit"
                                    testId="sku"
                                    disabled={readOnly}
                                    required
                                />
                            </div>

                            <div className="col-12 col-lg-4">
                                <Input
                                    name="mpn"
                                    label="MPN"
                                    help="Manufacturer Part Number"
                                    disabled={readOnly}
                                    testId="mpn"
                                />
                            </div>

                            <div className="col-12 col-lg-4">
                                <Input
                                    name="gtin"
                                    label="GTIN"
                                    help="Global Trade Item Number"
                                    disabled={readOnly}
                                    testId="gtin"
                                />
                            </div>

                            <div className="col-12">
                                <Input
                                    name="weight"
                                    label="Weight"
                                    type="number"
                                    min="0"
                                    testId="weight"
                                    disabled={readOnly}
                                    suffix={WEIGHT_UNIT}
                                />
                            </div>

                            <div className="col-12 col-lg-3">
                                <Input
                                    name="leadTimeGB"
                                    label="Lead Time (GB)"
                                    type="number"
                                    testId="leadTimeGB"
                                    disabled={readOnly}
                                    required
                                />
                            </div>

                            <div className="col-12 col-lg-3">
                                <Input
                                    name="leadTimeIE"
                                    label="Lead Time (IE)"
                                    type="number"
                                    testId="leadTimeIE"
                                    disabled={readOnly}
                                    required
                                />
                            </div>

                            <div className="col-12 col-lg-6">
                                <Input
                                    name="generation"
                                    label="Generation"
                                    testId="generation"
                                    disabled={readOnly}
                                />
                            </div>

                            <div className="col-12 col-lg-6">
                                <Input
                                    name="navSku"
                                    label="Nav SKU"
                                    testId="navSku"
                                    disabled={readOnly}
                                    required
                                />
                            </div>

                            <div className="col-12 col-lg-6">
                                <Input
                                    name="navProductName"
                                    label="Nav Product Name"
                                    testId="navProductName"
                                    disabled={readOnly}
                                    required
                                />
                            </div>

                            <div className="col-12 col-lg-6">
                                <Input
                                    name="blindToWindowReference"
                                    label="Blind to window reference"
                                    testId="blindToWindowReference"
                                    disabled={readOnly}
                                />
                            </div>

                            <div className="col-12 col-lg-6">
                                <Input
                                    name="windowToFlashingReference"
                                    label="Window to flashing reference"
                                    testId="windowToFlashingReference"
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                    </Group>
                </div>

                <div data-testid="option-values-section">
                    <Group key="option-values" id="option-values" name="Option Values">
                        <div className="row">
                            <div className="col-12">
                                <div data-testid="default-variant">
                                    <Checkbox
                                        name="default"
                                        label="Set as default variant"
                                        tooltip="Set as default to pre-set these options on your storefront."
                                        disabled={readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                        {values?.options?.length > 0 &&
                            values.options.map((option, idx) => (
                                <div className="row" key={`variant-option-${idx}`}>
                                    <div
                                        className={`col-12 ${
                                            option.productOption.type === 'swatch'
                                                ? 'col-md-4'
                                                : 'col-md-6'
                                        }`}
                                    >
                                        <Field name={`options.${idx}.displayValue`}>
                                            {({ field, form }) => {
                                                return (
                                                    <Input
                                                        label={`${option.productOption.name} Display Value`}
                                                        placeholder={option.productOption.name}
                                                        testId={`option-${idx}-display-value`}
                                                        name={field.name}
                                                        disabled={readOnly}
                                                        required
                                                        onBlur={() => {
                                                            if (field.value.length > 0) {
                                                                if (
                                                                    !!form.values.options[idx]
                                                                        .identifier === false
                                                                ) {
                                                                    const identifier = encodeURI(
                                                                        form.values.options[
                                                                            idx
                                                                            ].displayValue
                                                                            .split(' ')
                                                                            .join('-')
                                                                            .replace(
                                                                                /[^a-zA-Z0-9]/g,
                                                                                ''
                                                                            )
                                                                            .replace(/-+/g, '-')
                                                                            .toLowerCase()
                                                                    )

                                                                    form.setFieldValue(
                                                                        `options.${idx}.identifier`,
                                                                        identifier
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>

                                    {option.productOption.type === 'swatch' && (
                                        <div className={`col-12 col-md-4`}>
                                            <Input
                                                name={`options.${idx}.altValue`}
                                                label="Swatch Value"
                                                placeholder="Value"
                                                disabled={readOnly}
                                                testId={`option-${idx}-swatch-value`}
                                            />
                                        </div>
                                    )}

                                    <div
                                        className={`col-12 ${
                                            option.productOption.type === 'swatch'
                                                ? 'col-md-4'
                                                : 'col-md-6'
                                        }`}
                                    >
                                        <Field name={`options.${idx}.identifier`}>
                                            {({ field, form }) => {
                                                return (
                                                    <Input
                                                        name={field.name}
                                                        label={`${option.productOption.name} Identifier`}
                                                        placeholder={`${option.productOption.name} Identifier`}
                                                        tooltip="Identifier is used in the storefront URL when selections are made. Options are displayed in Identifer alphanumeric ascending order."
                                                        testId={`option-${idx}-identifier`}
                                                        required
                                                        disabled={readOnly}
                                                        onBlur={() => {
                                                            if (field.value?.length > 0) {
                                                                const identifier = encodeURI(
                                                                    field.value
                                                                        .split(' ')
                                                                        .join('-')
                                                                        .replace(
                                                                            /[^a-zA-Z0-9]/g,
                                                                            ''
                                                                        )
                                                                        .replace(/-+/g, '-')
                                                                        .toLowerCase()
                                                                )

                                                                form.setFieldValue(
                                                                    `options.${idx}.identifier`,
                                                                    identifier
                                                                )
                                                            }
                                                        }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>
                                </div>
                            ))}
                    </Group>
                </div>

                <div data-testid="availability">
                    <Group
                        key="availability-and-display"
                        id="availability-and-display"
                        name="Availability and Display"
                    >
                        <Select
                            name="status"
                            options={STATUS_OPTIONS}
                            defaultValue={STATUS_OPTIONS[0]}
                            label="Product Status"
                            testId="product-status-dropdown"
                            testIdItems="product-status-dropdown__item"
                            disabled={readOnly}
                            testIdIndex={0}
                        />

                        <Select
                            name="availability"
                            options={AVAILABILITY_OPTIONS}
                            defaultValue={AVAILABILITY_OPTIONS[0]}
                            label="Availability"
                            testId="availability-dropdown"
                            testIdItems="dropdown__item"
                            disabled={readOnly}
                            testIdIndex={1}
                        />
                    </Group>
                </div>

                <div data-testid="inventory">
                    <Group key="inventory" id="inventory" name="Inventory">
                        <div data-testid="track-quantity">
                            <Checkbox
                                name="manageStock"
                                label="Track Quantity"
                                disabled={readOnly}
                            />
                        </div>

                        <LocationManagement disabled={readOnly} />
                    </Group>
                </div>

                <div data-testid="prices">
                    <Group key="pricing" id="pricing" name="Pricing">
                        <div className="form-row">
                            {PRICE_OPTIONS.map((option, idx) => {
                                const label = products?.labels?.[option] || startCase(option)

                                return (
                                    <div className="col-12 col-lg-3" key={`price-option-${idx}`}>
                                        <Input
                                            name={option}
                                            type="number"
                                            min={0}
                                            step={0.01}
                                            disabled={readOnly}
                                            label={
                                                option === 'rrp'
                                                    ? products?.showRRPAsWasPrice
                                                        ? products?.labels?.[option] || 'Was'
                                                        : products?.labels?.[option] || 'RRP'
                                                    : label
                                            }
                                            placeholder={
                                                option === 'rrp'
                                                    ? products?.showRRPAsWasPrice
                                                        ? products?.labels?.[option] || 'Was Price'
                                                        : products?.labels?.[option] || 'RRP Price'
                                                    : `${label}`
                                            }
                                            prefix={
                                                showPricesAsCurrency
                                                    ? currencySymbols?.[option]
                                                        ? currencySymbols[option]
                                                        : '\u00A3'
                                                    : null
                                            }
                                            testId={option}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </Group>
                </div>

                <div data-testid="product-resources-section">
                    <Group key="product-resources" id="product-resources" name="Product Resources">
                        {image && (
                            <ImagePicker
                                name="variantResources.image"
                                label="Variant Images"
                                images={productResources?.image}
                                disabled={readOnly}
                            />
                        )}

                        {resources &&
                            Object.keys(resources)?.map(resource => {
                                if (
                                    resource.type === 'image' ||
                                    !!productResources[resource] === false
                                )
                                    return null

                                return (
                                    <Tags
                                        key={`variantResources.${resource}`}
                                        name={`variantResources.${resource}`}
                                        label={startCase(resource)}
                                        disabled={readOnly}
                                        options={
                                            productResources[resource]?.length > 0
                                                ? productResources[resource].map(opt => {
                                                    return {
                                                        label:
                                                            opt.description ||
                                                            opt.title ||
                                                            opt.location,
                                                        value: opt['@id'],
                                                    }
                                                })
                                                : []
                                        }
                                    />
                                )
                            })}
                    </Group>
                </div>
            </Form>

            {mode === MODE_EDIT && (
                <>
                    <Modal
                        isVisible={showDeleteDialog}
                        title="Delete Product Variant"
                        close={() => setShowDeleteDialog(false)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => setShowDeleteDialog(false),
                            },
                            {
                                type: 'btn-danger',
                                text: 'Delete Product Variant',
                                action: () => onDelete(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="mb-4 d-block"
                        />
                        Are you sure you would like to permanently delete this product variant?
                    </Modal>

                    <Modal
                        isVisible={showDuplicateDialog}
                        title="Duplicate Product"
                        close={() => setShowDuplicateDialog(false)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => setShowDuplicateDialog(false),
                            },
                            {
                                type: 'btn-success',
                                text: 'Duplicate Product',
                                action: () => {
                                    onDuplicate(values)
                                    setShowDuplicateDialog(false)
                                },
                            },
                        ]}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} size="4x" className="mb-4 d-block" />
                        Are you sure you would like to duplicate this product?
                    </Modal>
                </>
            )}
        </>
    )
}

ProductVariantsForm.displayName = 'ProductVariantsForm'

ProductVariantsForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default ProductVariantsForm
