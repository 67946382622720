import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages'

export const defaultTranslations = {
    pluginName: 'Carousel',
    pluginDescription: 'An image carousel',
}

export const defaultSettings = {
    Component: () => <>Component for this plugin was not provided</>,
    FormComponent: () => <>There is no form for this block</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faImages} size="3x" />,
    name: 'keylite/content/carousel',
}
