import {
    ProductsCarousel,
    MenuItemsCarousel,
} from "@peracto/peracto-blocks-tailwind";

import Accordion from "./Accordion";
import Container from "./Container";
import TextOnImage from "./TextOnImage";
import Carousel from "./Carousel";
import MenuItems from "./MenuItems";
import Products from "./Products";
import IconAndText from "./IconAndText";
import HeroBanner from "./HeroBanner";
import RoundedCard from "./RoundedCard";
import Panels from "./Panels";
import Testimonials from "./Testimonials";
import InfoPanels from "./InfoPanels";
import HappyCustomers from "./HappyCustomers";

export default {
    plugins: {
        content: [
            Carousel,
            Accordion,
            Products,
            ProductsCarousel,
            MenuItemsCarousel,
            TextOnImage,
            MenuItems,
            IconAndText,
            HeroBanner,
            RoundedCard,
            Panels,
            Testimonials,
            InfoPanels,
            HappyCustomers,
        ],
        layout: [Container],
    },
};
