import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'

export const usePrevNextButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

export const PrevButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="bg-brand-black text-brand-white text-sm w-[30px] h-[30px] lg:text-lg lg:w-[60px] lg:h-[60px] rounded-full absolute left-0 top-1/2 -translate-y-1/2 flex items-center justify-center"
      type="button"
      {...restProps}
    >
        <FontAwesomeIcon icon={faChevronLeft} />
      {children}
    </button>
  )
}

export const NextButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="bg-brand-black text-brand-white text-sm w-[30px] h-[30px] lg:text-lg lg:w-[60px] lg:h-[60px] rounded-full absolute right-0 top-1/2 -translate-y-1/2 flex items-center justify-center"
      type="button"
      {...restProps}
    >
        <FontAwesomeIcon icon={faChevronRight} />
    </button>
  )
}
