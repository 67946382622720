import createPlugin from "./createPlugin";

import ProductsRenderer from "./Renderer/ProductsRenderer";
import ProductsForm from "./Form/ProductsForm";

const productsPlugin = (settings) =>
    createPlugin({
        Renderer: ProductsRenderer,
        Controls: ProductsForm,
        ...settings,
    });

const products = productsPlugin();
export default products;

export { productsPlugin, ProductsRenderer };
