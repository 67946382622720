import React from "react";
import styled from "@emotion/styled";

import InnerHtml from "@peracto/peracto-editor-ui/dist/InnerHtml";

const Text = styled(InnerHtml)`
    overflow: auto;
    border: 1px solid transparent;
    margin: 0 auto 0 0;
    padding-bottom: 15px;

    > :last-child {
        margin-bottom: 0;
    }
`;

const AccordionContent = ({ content }) => {
    return <Text content={content} />;
};

export default AccordionContent;
