import React from 'react'
import cn from 'classnames'
import { buttonVariants } from 'theme/buttonVariants'

const Component = ({
    state: {
        title,
        text,
        buttonText,
        buttonLink,
        isExternal,
        image,
        imageAlt,
    },
}) => {
    return (
        <div>
            <div
                data-testid="text-on-image"
                className="relative flex items-center rounded-[20px] p-[20px] py-[45px] lg:min-h-[410px] lg:px-[60px] lg:py-[90px]"
            >
                <div className="relative z-10 rounded-[20px] bg-brand-white p-[30px] lg:max-w-[500px] lg:p-[30px]">
                    <h2
                        data-testid="text-on-image__heading"
                        className="mb-[15px] text-brand-secondary"
                    >
                        {title}
                    </h2>
                    <p data-testid="text-on-image__text">
                        {text}
                    </p>

                    <a href={buttonLink} {...isExternal && { target: '_blank' }}>
                        <button
                            data-testid="text-on-image__button"
                            variant="secondary"
                            className={cn("mt-[30px]", buttonVariants.secondary.solidClasses)}
                        >
                            {buttonText}
                        </button>
                    </a>
                </div>

                <img
                    data-testid="text-on-image__image"
                    src={image}
                    alt={imageAlt}
                    className="rounded-[20px] absolute top-0 left-0 z-0 object-cover w-full h-full"
                />
            </div>
        </div>
    )
}

export default Component
