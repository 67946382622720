import React from 'react'
import TextInput from '@peracto/peracto-editor-ui/dist/TextInput'
import Slider from '@peracto/peracto-editor-ui/dist/Slider'
import File from '@peracto/peracto-editor-ui/dist/File'

const Form = ({
    onChange,
    state: {
        numberOfSlides,
        slides = [],
    },
}) => {
    const updateSlides = (length) => {
        if (length > slides.length) {
            return [...slides, ...Array.from({ length: length - slides.length }, () => ({ image: 'https://placehold.co/800x800/png', imageAlt: 'Image Alt' }))];
        }
        
        return slides.slice(0, length);
    };

    return (
        <>
            <Slider
                label="Number of Slides"
                min={1}
                max={10}
                step={1}
                value={numberOfSlides}
                onChange={e => {
                    const value = parseInt(e.target.value, 10);
                    onChange({
                        numberOfSlides: value,
                        slides: updateSlides(value),
                    });
                }}
            />
            <div className="flex flex-col flex-wrap gap-y-[15px]">
                {slides?.map((slide, index) => (
                    <div key={index}>
                        <File
                            label="Image"
                            value={slide.image || ''}
                            onChange={value => onChange({ slides: slides.map((s, i) => i === index ? { ...s, image: value } : s) })}
                            isInline={false}
                        />
                        <TextInput
                            label="Image Alt"
                            value={slide.imageAlt || ''}
                            onChange={e => onChange({ slides: slides.map((s, i) => i === index ? { ...s, imageAlt: e.target.value } : s) })}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default Form
