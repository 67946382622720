import React from "react";

import { defaultSettings } from "./defaults/settings";
import Component from "./Component";
import Form from "./Form";

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings };

    return {
        Component: (props) => <Component {...props} {...mergedSettings} />,
        FormComponent: (props) => <Form {...props} />,

        name: "keylite/content/rounded-card",
        version: "1.0.0",
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        createInitialState: () => ({
            label: mergedSettings.defaultLabel,
            cards: [],
            selectedCardIndex: 0,
        }),
    };
};

export default createPlugin;
