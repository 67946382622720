import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleLandscape } from "@fortawesome/pro-regular-svg-icons/faRectangleLandscape";

const Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faRectangleLandscape} size="6x" />
    </div>
);

const CardsComponent = ({
    state: { cards = [], selectedCardIndex = null },
    isEditMode,
    onChange,
    focused,
}) => {
    return (
        <>
            {cards?.length > 0 ? (
                <div className="flex flex-col md:flex-row content__rounded-card gap-[30px]">
                    {cards.map((card, idx) => {
                        return (
                            <div
                                key={`card_${idx}`}
                                style={{
                                    boxShadow:
                                        selectedCardIndex === idx &&
                                        focused &&
                                        "inset 0 0 0 3px red",
                                    backgroundImage: `url(${card.image})`,
                                }}
                                className={`flex flex-1 flex-col items-center mb-0 bg-brand-white product-card px-[15px] md:px-[75px] py-[30px] md:py-[90px] border relative bg-cover bg-center ${
                                    card.border
                                        ? "border-brand-grey"
                                        : "!border-transparent"
                                } md:rounded-[20px] overflow-hidden items-start`}
                                onClick={(e) => {
                                    if (isEditMode) {
                                        e.preventDefault();
                                        onChange({ selectedCardIndex: idx });
                                    }
                                }}
                            >
                                <div className="flex flex-col items-center gap-[30px] lg:gap-[45px] h-full z-[20]">
                                    <div className="flex flex-col items-center flex-grow gap-[15px]">
                                        <h4 className="!text-[28px] !lg:text-[45px] text-center !leading-[32px] !lg:leading-[51px] text-white !mb-0">
                                            {card.heading || ""}
                                        </h4>

                                        <p className="text-[18px] lg:text-[20px] leading-[26px] lg:leading-[28px] mb-0 text-center text-white">
                                            {card.content || ""}
                                        </p>
                                    </div>

                                    {card.buttonText.length > 0 && (
                                        <button className="rounded-[100px] py-[10px] px-[30px] text-brand-white border !border-brand-white font-bold">
                                            {card.buttonText || ""}
                                        </button>
                                    )}
                                </div>

                                <div
                                    data-testid="hero__desktop-overlay"
                                    className="bg-[rgba(0,0,0,0.2)] absolute inset-0 z-[10] block bg-blend-saturation"
                                ></div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Icon />
            )}
        </>
    );
};

export default CardsComponent;
