import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentSmile } from "@fortawesome/pro-regular-svg-icons/faCommentSmile";
import styled from "@emotion/styled";

const ScrollbarStyles = styled.div`
    ::-webkit-scrollbar {
        height: 20px;
    }

    /* 4px scroll thumb. 10px margin from right margin */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 15px 15px #d6d8d9;
        border-top: solid 15px transparent;
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 15px 15px #333;
        border-top: solid 15px transparent;
    }

    ::-webkit-scrollbar-button {
        display: none;
    }
`;

const Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faCommentSmile} size="6x" />
    </div>
);

const ImageRowComponent = ({
    state: { images = [], border, selectedCardIndex = null },
    isEditMode,
    onChange,
    focused,
}) => {
    return (
        <>
            {images?.length > 0 ? (
                <ScrollbarStyles className="flex flex-row content__happy-customers overflow-auto gap-[30px]">
                    {images.map((image, idx) => {
                        return (
                            <div
                                key={`image-row_${idx}`}
                                style={{
                                    boxShadow:
                                        selectedCardIndex === idx &&
                                        focused &&
                                        "0 0 0 3px red",
                                }}
                                className={`flex bg-brand-white border relative min-w-[170px] max-w-[170px] min-h-[114px] max-h-[114px] justify-center items-center ${
                                    border
                                        ? "border-brand-grey"
                                        : "!border-transparent"
                                }`}
                                onClick={(e) => {
                                    if (isEditMode) {
                                        e.preventDefault();
                                        onChange({ selectedCardIndex: idx });
                                    }
                                }}
                            >
                                {image.link.length > 0 ? (
                                    <a
                                        href={image.link}
                                        {...(image?.openInNewTab && {
                                            target: "_blank",
                                            rel: "noindex nofollow",
                                        })}
                                    >
                                        <img
                                            src={image.image}
                                            alt={image.alt}
                                            className="h-full"
                                        />
                                    </a>
                                ) : (
                                    <img
                                        src={image.image}
                                        alt={image.alt}
                                        className="h-full"
                                    />
                                )}
                            </div>
                        );
                    })}
                </ScrollbarStyles>
            ) : (
                <Icon />
            )}
        </>
    );
};

export default ImageRowComponent;
