import createPlugin from "./createPlugin";

import PanelsRenderer from "./Renderer/PanelsRenderer";
import PanelsForm from "./Form/PanelsForm";

const panelsPlugin = (settings) =>
    createPlugin({
        Renderer: PanelsRenderer,
        Controls: PanelsForm,

        ...settings,
    });

const panels = panelsPlugin();
export default panels;

export { panelsPlugin, PanelsRenderer };
