import createPlugin from './createPlugin'

import { default as CarouselComponent } from './Component'

const carouselPlugin = settings =>
    createPlugin({
        Component: CarouselComponent,
        ...settings,
    })

const carousel = carouselPlugin()
export default carousel

export { carouselPlugin, CarouselComponent }
