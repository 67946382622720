import createPlugin from './createPlugin'

import { default as TextOnImageComponent } from './Component'

const textOnImagePlugin = settings =>
    createPlugin({
        Component: TextOnImageComponent,
        ...settings,
    })

const textOnImage = textOnImagePlugin()
export default textOnImage

export { textOnImagePlugin, TextOnImageComponent }
