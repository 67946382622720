import React, { useEffect } from "react";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import PlainTextarea from "@peracto/peracto-editor-ui/dist/PlainTextarea";
import File from "@peracto/peracto-editor-ui/dist/File";

import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

const TestimonialsForm = ({
    onChange,
    state: { testimonials, border, selectedCardIndex },
}) => {
    const DEFAULT_CARD = {
        image: "https://placehold.co/200x200/EEE/31343C",
        content:
            "Velit quis incididunt consequat nisi reprehenderit adipisicing. Labore nostrud sunt minim aliqua aliqua sunt incididunt officia. Adipisicing sint non magna culpa non eiusmod proident esse dolore adipisicing ad irure. Occaecat cillum culpa officia reprehenderit irure.",
        customerName: "Customer Name",
        customerJobTitle: "Customer Job Title",
    };

    useEffect(() => {
        return () => {
            onChange({ selectedCardIndex: null });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {testimonials?.length === 0 && (
                <>
                    <p>Click 'Add Testimonial' to get started</p>
                    <hr />
                </>
            )}
            {selectedCardIndex !== null &&
                testimonials &&
                testimonials[selectedCardIndex] && (
                    <>
                        <PlainTextarea
                            label="Content"
                            onChange={(e) => {
                                const testimonialsClone = [...testimonials];
                                testimonialsClone[selectedCardIndex].content =
                                    e.target.value;

                                onChange({
                                    testimonials: testimonialsClone,
                                });
                            }}
                            value={testimonials[selectedCardIndex].content}
                        />

                        <File
                            label="Customer Image"
                            value={testimonials[selectedCardIndex].image || ""}
                            onChange={(value) => {
                                const testimonialsClone = [...testimonials];
                                testimonialsClone[selectedCardIndex].image =
                                    value;

                                onChange({
                                    testimonials: testimonialsClone,
                                });
                            }}
                            isInline={false}
                        />

                        <TextInput
                            label="Customer Name"
                            onChange={(e) => {
                                const testimonialsClone = [...testimonials];
                                testimonialsClone[
                                    selectedCardIndex
                                ].customerName = e.target.value;

                                onChange({
                                    testimonials: testimonialsClone,
                                });
                            }}
                            value={testimonials[selectedCardIndex].customerName}
                        />

                        <TextInput
                            label="Customer Job Title"
                            onChange={(e) => {
                                const testimonialsClone = [...testimonials];
                                testimonialsClone[
                                    selectedCardIndex
                                ].customerJobTitle = e.target.value;

                                onChange({
                                    testimonials: testimonialsClone,
                                });
                            }}
                            value={
                                testimonials[selectedCardIndex].customerJobTitle
                            }
                        />

                        <button
                            className="btn btn-sm btn-danger w-100 mt-2"
                            onClick={(e) => {
                                const testimonialsClone = [...testimonials];

                                testimonialsClone.splice(selectedCardIndex, 1);

                                onChange({
                                    testimonials: testimonialsClone,
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />{" "}
                            Remove Testimonial
                        </button>
                    </>
                )}

            <hr className="my-2" />

            <Checkbox
                label="Show Borders"
                checked={border}
                onChange={(e) => {
                    onChange({
                        border: e.target.checked,
                    });
                }}
            />

            <button
                className="btn btn-sm btn-secondary w-100"
                onClick={(e) => {
                    e.preventDefault();

                    const testimonialsClone = [...testimonials, DEFAULT_CARD];
                    onChange({
                        testimonials: testimonialsClone,
                    });
                }}
            >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add
                Testimonial
            </button>
        </>
    );
};

export default TestimonialsForm;
