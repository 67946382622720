import React from "react";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import File from "@peracto/peracto-editor-ui/dist/File";
import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";

const PanelsForm = ({ onChange, state }) => {
    const {
        flipped,
        largePanelImage,
        largePanelImageAlt,
        largePanelImageLink,
        largePanelImageLinkExternal,
        smallPanelImageTop,
        smallPanelImageTopAlt,
        smallPanelImageTopLink,
        smallPanelImageTopLinkExternal,
        smallPanelImageBottom,
        smallPanelImageBottomAlt,
        smallPanelImageBottomLink,
        smallPanelImageBottomLinkExternal,
    } = state;

    return (
        <>
            <div className="mb-0 form-group">
                <Checkbox
                    label="Flipped"
                    checked={flipped}
                    onChange={(e) => onChange({ flipped: e.target.checked })}
                />

                <hr
                    className="my-2"
                    style={{
                        borderTop: "1px solid #666",
                    }}
                />

                <File
                    label="Large Panel Image"
                    onChange={(value) => onChange({ largePanelImage: value })}
                    value={largePanelImage}
                />
                <TextInput
                    label="Large Panel Image Alt"
                    onChange={(e) =>
                        onChange({ largePanelImageAlt: e.target.value })
                    }
                    value={largePanelImageAlt}
                />
                <TextInput
                    label="Large Panel Image Link"
                    onChange={(e) =>
                        onChange({ largePanelImageLink: e.target.value })
                    }
                    value={largePanelImageLink}
                />
                <Checkbox
                    label="External Link"
                    checked={largePanelImageLinkExternal}
                    onChange={(e) =>
                        onChange({
                            largePanelImageLinkExternal: e.target.checked,
                        })
                    }
                />

                <hr
                    className="my-2"
                    style={{
                        borderTop: "1px solid #666",
                    }}
                />

                <File
                    label="Small Panel Image Top"
                    onChange={(value) =>
                        onChange({ smallPanelImageTop: value })
                    }
                    value={smallPanelImageTop}
                />
                <TextInput
                    label="Small Panel Image Top Alt"
                    onChange={(e) =>
                        onChange({ smallPanelImageTopAlt: e.target.value })
                    }
                    value={smallPanelImageTopAlt}
                />
                <TextInput
                    label="Small Panel Image Top Link"
                    onChange={(e) =>
                        onChange({ smallPanelImageTopLink: e.target.value })
                    }
                    value={smallPanelImageTopLink}
                />
                <Checkbox
                    label="External Link"
                    checked={smallPanelImageTopLinkExternal}
                    onChange={(e) =>
                        onChange({
                            smallPanelImageTopLinkExternal: e.target.checked,
                        })
                    }
                />

                <hr
                    className="my-2"
                    style={{
                        borderTop: "1px solid #666",
                    }}
                />

                <File
                    label="Small Panel Image Bottom"
                    onChange={(value) =>
                        onChange({ smallPanelImageBottom: value })
                    }
                    value={smallPanelImageBottom}
                />
                <TextInput
                    label="Small Panel Image Bottom Alt"
                    onChange={(e) =>
                        onChange({ smallPanelImageBottomAlt: e.target.value })
                    }
                    value={smallPanelImageBottomAlt}
                />
                <TextInput
                    label="Small Panel Image Bottom Link"
                    onChange={(e) =>
                        onChange({ smallPanelImageBottomLink: e.target.value })
                    }
                    value={smallPanelImageBottomLink}
                />
                <Checkbox
                    label="External Link"
                    checked={smallPanelImageBottomLinkExternal}
                    onChange={(e) =>
                        onChange({
                            smallPanelImageBottomLinkExternal: e.target.checked,
                        })
                    }
                />
            </div>
        </>
    );
};

export default PanelsForm;
