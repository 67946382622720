/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Link, Redirect, useParams, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { Heading, NotFoundMessage, UnauthorisedMessage } from '@peracto/peracto-ui'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'

import { GET_ONE, UPDATE, CREATE, useClient, getSchemaFromResource } from '@peracto/client'
import * as yup from 'yup'
import { Helmet } from 'react-helmet'
import { useConfig } from '@peracto/peracto-config'
import * as qs from 'querystring'
import cloneDeep from 'lodash/cloneDeep'

import { MODE_EDIT } from '../ProductVariantsForm'

import {
    formatDataForForm,
    formatDataForAPI,
    convertPricesToCurrency,
    convertPricesToIntegers,
    getProductName,
} from '../../../../node_modules/@peracto/peracto-catalogue/dist/components/Products/util'

import { formatDataForVariantForm } from '../../../../node_modules/@peracto/peracto-catalogue/dist/components/ProductVariants/util'

const ProductVariantsEditContainer = ({ parentProduct, children }) => {
    const { id } = useParams()
    const query = useLocation()
    const queryParams = query.search ? qs.parse(query.search.slice(1)) : null

    return (
        <div className="form-container">
            <Heading
                name={
                    parentProduct
                        ? `Edit Product Variant of ${parentProduct.name} (${parentProduct.sku})`
                        : 'Loading...'
                }
            >
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link
                        className="btn btn-outline-primary text-nowrap"
                        to={queryParams?.to ? `/${queryParams.to}` : `/products/${id}/variants`}
                    >
                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Back to Product Variants
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    )
}

const ProductVariantsEdit = ({ ProductVariantsForm }) => {
    const { client, getResource } = useClient()
    const [loading, setLoading] = useState(true)
    const [unauthorised, setUnauthorised] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [redirect, setRedirect] = useState()
    const [productResources, setProductResources] = useState({})
    const { id, variantId } = useParams()
    const pathname = `/products/${id}/variants/${variantId}`

    const config = useConfig()
    const { products } = config.get('features', {})

    const additionalPriceTypes = products?.additionalPriceTypes ?? []
    const showPricesAsCurrency = products?.showPricesAsCurrency ?? true

    const [product, setProduct] = useState()
    const [parentProduct, setParentProduct] = useState()

    const fetchProductVariant = async () => {
        setLoading(true)
        try {
            const { data, response } = await client(GET_ONE, 'product-variants', {
                id: `/product-variants/${variantId}`,
            })

            const { data: productData } = await client(GET_ONE, 'products', {
                id: `/products/${id}`,
            })

            let formatted = formatDataForForm(data)

            if (showPricesAsCurrency) {
                formatted = convertPricesToCurrency(formatted, additionalPriceTypes)
            }

            formatted = formatDataForVariantForm(formatted, productData)

            const resources = {}
            productData?.resources?.forEach(
                r =>
                    (resources[r.type] =
                        resources[r.type]?.length > 0 ? [...resources[r.type], r] : [r])
            )

            setProduct(formatted)
            setProductResources(resources)
            setParentProduct({ sku: productData.sku, name: getProductName(productData) })

            setLoading(false)

            if (response.status === 404) {
                setRedirect(`/products/${id}/variants`)
            }
        } catch (e) {
            console.error(e)

            if (e.status === 403) {
                setUnauthorised(true)
            }

            if (e.status === 404) {
                setNotFound(true)
            }

            setLoading(false)
            setRedirect(`/products/${id}/variants`)
        }
    }

    useEffect(() => {
        fetchProductVariant()
    }, [pathname])

    const schema = getSchemaFromResource(getResource('product-variants')).shape({
        mpn: yup.string().nullable(),
        gtin: yup.string().nullable(),
    })

    const onSubmit = async (data, actions) => {
        const cloneData = cloneDeep(data)

        let formattedData

        if (showPricesAsCurrency) {
            formattedData = convertPricesToIntegers(data, additionalPriceTypes)
            formattedData = formatDataForAPI({ data: formattedData, isVariant: true })
        } else {
            formattedData = formatDataForAPI({ data, isVariant: true })
        }

        try {
            await client(UPDATE, 'product-variants', {
                id: `/product-variants/${variantId}`,
                data: {
                    ...formattedData,
                    product: `/products/${id}`,
                },
            })

            actions.setSubmitting(false)
            fetchProductVariant()
            toast.success('Variant successfully updated!')
        } catch (e) {
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                e.error.body.violations.map(error => {
                    actions.setFieldError(error.propertyPath, error.message)
                })
            }
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
            actions.setValues(cloneData)
            actions.setSubmitting(false)
        }
    }

    const onDuplicate = async data => {
        let formattedData

        if (showPricesAsCurrency) {
            formattedData = convertPricesToIntegers(data, additionalPriceTypes)
            formattedData = formatDataForAPI({
                data: formattedData,
                isDuplicate: true,
                isVariant: true,
            })
        } else {
            formattedData = formatDataForAPI({
                data,
                isDuplicate: true,
                isVariant: true,
            })
        }

        try {
            const response = await client(CREATE, 'product-variants', {
                data: {
                    ...formattedData,
                    product: `/products/${id}`,
                },
            })

            if (response?.data?.originId) {
                setRedirect(`/products/${id}/variants/${response.data.originId}`)
            } else {
                setRedirect(`/products/${id}/variants`)
            }

            toast.success('Product successfully duplicated!')
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    if (loading) {
        return (
            <ProductVariantsEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </ProductVariantsEditContainer>
        )
    }

    if (unauthorised) {
        return <UnauthorisedMessage />
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url={`/products/${id}/variants`}
                message="The product variant you're looking for could not be found"
                buttonLabel="Go to Product Variants"
            />
        )
    }

    return (
        <ProductVariantsEditContainer parentProduct={parentProduct}>
            {redirect && <Redirect push to={redirect} />}
            <Helmet>
                <title>{product?.sku || 'Product Variant'} | Edit | Peracto</title>
            </Helmet>

            <ProductVariantsForm
                values={product}
                mode={MODE_EDIT}
                productResources={productResources}
                onSubmit={onSubmit}
                onDuplicate={onDuplicate}
                schema={schema}
            />
        </ProductVariantsEditContainer>
    )
}

export default ProductVariantsEdit
