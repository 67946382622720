import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import RoundedCardRenderer from "./Renderer";

const RoundedCardForm = lazyLoad(() => import("./Form"));

const RoundedCardPlugin = (settings) =>
    createPlugin({
        Renderer: RoundedCardRenderer,
        Controls: RoundedCardForm,
        ...settings,
    });

const RoundedCard = RoundedCardPlugin();
export default RoundedCard;

export { RoundedCardPlugin, RoundedCardRenderer };
