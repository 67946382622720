import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'

export const defaultTranslations = {
    pluginName: 'Accordion',
    pluginDescription: 'Add a list of accordion items',
}

export const defaultSettings = {
    Controls: () => <>Controls for this plugin were not provided</>,
    Renderer: () => <>Renderer for this plugin was not provided</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faChevronDown} size="3x" />,
}
