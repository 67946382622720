import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/pro-regular-svg-icons/faList'

export const defaultTranslations = {
    pluginName: 'Menu Items',
    pluginDescription: 'A menu items block',
}

export const defaultSettings = {
    Component: () => <>Component for this plugin was not provided</>,
    FormComponent: () => <>There is no form for this block</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faList} size="3x" />,
    name: 'peracto/content/menuItems',
}
