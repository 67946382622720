import createPlugin from "./createPlugin";

import { default as HeroBannerComponent } from "./Component";

const heroBannerPlugin = (settings) =>
    createPlugin({
        Component: HeroBannerComponent,
        ...settings,
    });

const heroBanner = heroBannerPlugin();
export default heroBanner;

export { heroBannerPlugin, HeroBannerComponent };
