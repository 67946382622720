import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleWide } from '@fortawesome/pro-regular-svg-icons/faRectangleWide'

export const defaultTranslations = {
    pluginName: 'Text On Image',
    pluginDescription: 'A text on image block', 
}

export const defaultSettings = {
    Component: () => <>Component for this plugin was not provided</>,
    FormComponent: () => <>There is no form for this block</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faRectangleWide} size="3x" />,
    name: 'keylite/content/text-on-image',
}
