/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useConfig } from "@peracto/peracto-config";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import PlainTextarea from "@peracto/peracto-editor-ui/dist/PlainTextarea";
import Textarea from "@peracto/peracto-editor-ui/dist/Textarea";
import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faArrowsV } from "@fortawesome/pro-regular-svg-icons/faArrowsV";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import startCase from "lodash/startCase";
import AccordionItemSort from "./AccordionItemSort";

const AccordionForm = ({
    onChange,
    state: { accordion = [], selectedAccordionIndex },
}) => {
    const config = useConfig();
    const { theme } = config.get("editorConfig") || [];
    const [manageItemOrder, setManageItemOrder] = useState(false);

    const themeColours = [];

    if (theme?.colors?.brand) {
        for (const [label, colour] of Object.entries(theme.colors.brand)) {
            themeColours.push({
                label: startCase(label),
                value: colour,
            });
        }
    }

    const themeFonts = [];

    if (theme?.fonts) {
        Object.keys(theme.fonts).map((font) =>
            themeFonts.push({
                label: startCase(font),
                value: font,
            })
        );
    }

    const DEFAULT_ACCORDION_ITEM = {
        anchor: "",
        text: "",
        label: "",
        openByDefault: false,
    };

    useEffect(() => {
        return () => {
            onChange({ selectedAccordionIndex: null });
        };
    }, []);

    return (
        <>
            <button
                className={`btn btn-sm w-100 mb-2 ${
                    manageItemOrder ? "btn-success" : "btn-secondary"
                }`}
                onClick={() => {
                    setManageItemOrder(!manageItemOrder);
                }}
            >
                <FontAwesomeIcon
                    icon={manageItemOrder ? faCheck : faArrowsV}
                    className="mr-2"
                />{" "}
                {manageItemOrder ? "Done" : "Manage Item Order"}
            </button>

            {manageItemOrder ? (
                <AccordionItemSort accordion={accordion} onChange={onChange} />
            ) : (
                <>
                    {selectedAccordionIndex !== null &&
                        accordion &&
                        accordion[selectedAccordionIndex] && (
                            <>
                                <PlainTextarea
                                    label="Heading Text"
                                    onChange={(e) => {
                                        const accordionClone = [...accordion];
                                        accordionClone[
                                            selectedAccordionIndex
                                        ].label = e.target.value;

                                        onChange({
                                            accordion: accordionClone,
                                        });
                                    }}
                                    onBlur={(e) => {
                                        if (
                                            !!accordion[selectedAccordionIndex]
                                                .anchor === false
                                        ) {
                                            const accordionClone = [
                                                ...accordion,
                                            ];
                                            accordionClone[
                                                selectedAccordionIndex
                                            ].anchor = e.target.value
                                                .replace(/\W/g, "_")
                                                .toLowerCase()
                                                .substr(0, 30);

                                            onChange({
                                                accordion: accordionClone,
                                            });
                                        }
                                    }}
                                    value={
                                        accordion[selectedAccordionIndex].label
                                    }
                                />

                                <Textarea
                                    label="Accordion Content"
                                    onChange={(e, editor) => {
                                        const accordionClone = [...accordion];
                                        accordionClone[
                                            selectedAccordionIndex
                                        ].text = editor.getData();

                                        onChange({
                                            accordion: accordionClone,
                                        });
                                    }}
                                    value={
                                        accordion[selectedAccordionIndex].text
                                    }
                                />

                                <Checkbox
                                    label="Open By Default"
                                    checked={
                                        accordion[selectedAccordionIndex]
                                            .openByDefault
                                    }
                                    onChange={(e) => {
                                        const accordionClone = [...accordion];
                                        accordionClone[
                                            selectedAccordionIndex
                                        ].openByDefault = e.target.checked;
                                        onChange({
                                            accordion: accordionClone,
                                        });
                                    }}
                                />

                                <TextInput
                                    label="Anchor"
                                    onChange={(e) => {
                                        const accordionClone = [...accordion];
                                        accordionClone[
                                            selectedAccordionIndex
                                        ].anchor = e.target.value;
                                        onChange({
                                            accordion: accordionClone,
                                        });
                                    }}
                                    onBlur={(e) => {
                                        const accordionClone = [...accordion];
                                        const currentAnchor =
                                            accordionClone[
                                                selectedAccordionIndex
                                            ].anchor;

                                        accordionClone[
                                            selectedAccordionIndex
                                        ].anchor = currentAnchor
                                            .replace(/\W/g, "_")
                                            .toLowerCase()
                                            .substr(0, 30);
                                        onChange({
                                            accordion: accordionClone,
                                        });
                                    }}
                                    value={
                                        accordion[selectedAccordionIndex].anchor
                                    }
                                />

                                <button
                                    className="btn btn-sm btn-danger w-100 mt-2"
                                    onClick={(e) => {
                                        const accordionClone = [...accordion];

                                        accordionClone.splice(
                                            selectedAccordionIndex,
                                            1
                                        );

                                        onChange({
                                            accordion: accordionClone,
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="mr-2"
                                    />{" "}
                                    Remove Accordion Item
                                </button>
                            </>
                        )}

                    {selectedAccordionIndex !== null &&
                        accordion?.length > 0 && (
                            <hr
                                className="my-2"
                                style={{
                                    borderTop: "1px solid #666",
                                }}
                            />
                        )}

                    <button
                        className="btn btn-sm btn-secondary w-100"
                        onClick={(e) => {
                            const accordionClone = [
                                ...accordion,
                                {
                                    ...DEFAULT_ACCORDION_ITEM,
                                    label: `Item Heading ${
                                        accordion.length + 1
                                    }`,
                                    anchor: `item_heading_${
                                        accordion.length + 1
                                    }`,
                                },
                            ];
                            onChange({
                                accordion: accordionClone,
                            });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />{" "}
                        Add Accordion Item
                    </button>
                </>
            )}
        </>
    );
};

export default AccordionForm;
